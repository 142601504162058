<template>
	<v-layout @click="open" wrap :style="{ height: '50px', cursor: 'pointer' }">
		<v-flex v-if="!stop.isAis" xs1 class="pa-2 pl-0 pt-3 pb-0">
			<v-icon :color="color">fas fa-circle</v-icon>
		</v-flex>
		<v-flex v-else xs1 class="pa-2 pl-0 pt-3 pb-0">
			<div class="centered">
				<svg-icon group="tabs" icon="tabais" :width="28" />
			</div>
			<!-- <v-icon>fas fa-signal-stream</v-icon> -->
		</v-flex>
		<v-flex :class="{ xs8: isPlanAuthPanel, xs10: !isPlanAuthPanel }" class="pa-2 pl-8 pb-0">
			<v-row :style="{ position: vesselName ? 'relative' : 'absolute' }">
				<p class="text-h6 mb-0 font-weight-black text-truncate">{{ vesselName }}</p>
			</v-row>
			<v-row style="position: relative">
				<p class="text-h6 pt-0 mt-0 font-weight-bold text-truncate" style="color: #6f7480">{{ vesselType }}</p>
			</v-row>
		</v-flex>
		<v-flex v-if="isPlanAuthPanel" xs1 class="pa-2 pr-0 pt-3 pb-0" style="text-align: center">
			<v-icon
				@click.native.stop=""
				@click="toggleAuthorizedAction"
				color="primary"
				small
				:class="stop.isRemovedToAuthorized ? ' mb2 fa-kit fa-solid-not-visualize' : ' mb2 fa-kit fa-solid-visualize'"
			></v-icon>
		</v-flex>
		<v-flex v-if="!stop.isRemovedToAuthorized" xs1 class="pa-2 pr-0 pt-3 pb-0" style="text-align: right">
			<v-icon @click.native.stop="" @click="openPanel(stop)" color="black">far fa-arrow-right</v-icon>
		</v-flex>
	</v-layout>
</template>

<script>
import { getPanelOptions } from '@/lib/utils/features';
import SvgIcon from '@/components/icon/SvgIcon';

/** Mixins */
import StopsServiceMixin from '@/mixins/StopsServiceMixin';

/** Utils */
import colorUtils from '@/components/operations/map/styles/colorUtils';

/** Constants */
import { MAP_AIS_ID, TYPE_VIS, STATUS_VIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';
import { REQUESTEDS_PANEL_ID, PLANNING_PANEL_ID } from '@/lib/variables/panels';
import { STOP_INSTANT_LAYER_ID } from '@/components/operations/map/constants/layers';

export default {
	components: {
		SvgIcon
	},
	mixins: [StopsServiceMixin],
	props: {
		stop: {
			type: Object,
			required: true
		},
		currentmap: {
			type: String,
			required: true
		}
	},
	created() {},
	computed: {
		isPlanningPanelId() {
			if (this.isCurrentMapAis) {
				return false;
			}
			if (this.$store.getters.panelParentActive == PLANNING_PANEL_ID) {
				return true;
			}
			return false;
		},
		isPlanAuthPanel() {
			if (this.isCurrentMapAis) {
				return false;
			}
			if (this.$store.getters.panelParentActive == PLANNING_PANEL_ID || this.$store.getters.panelParentActive == REQUESTEDS_PANEL_ID) {
				return true;
			}
			return false;
		},
		activePanel() {
			return this.$store.getters.activePanelPeriods;
		},
		activePanelOptions() {
			return this.$store.getters.activePanelOptionsPeriods;
		},
		icon() {
			return '';
		},
		vesselName() {
			return this.stop?.vesselname || this.stop.name;
		},
		status() {
			return this.stop?.status || '';
		},
		vesselType() {
			const text = this.stop?.vesseltypedescription || this.stop?.vesseltype || this.stop?.shiptype;
			return (text ?? '').toLowerCase().includes('not available') && this.vesselName == null ? this.stop?.mmsi : text;
		},
		isCurrentMapAis() {
			return this.currentmap == MAP_AIS_ID;
		},
		defaultBackgroundColor() {
			return this.$store.getters.defaultBackgroundColor;
		},
		statusColor() {
			if (!this.$store.getters.stopsStatusValues) {
				return this.defaultBackgroundColor;
			}
			let color = this.$store.getters.stopsStatusValues.filter((status) => status.stopstatuscode == this.stop.statusid)[0].colorhex;
			return color ? color : this.defaultBackgroundColor;
		},
		typeColor() {
			if (!this.$store.getters.stopsTypeValues) {
				return this.defaultBackgroundColor;
			}
			return this.stop.vesseltype ? this.$store.getters.stopsTypeValues[this.stop.vesseltype].color : this.defaultBackgroundColor;
		},
		operationsColor() {
			if (!this.$store.getters.stopsOperationsValues) {
				return this.defaultBackgroundColor;
			}
			return colorUtils.getColorOperationsStop(this.stop);
		},
		color() {
			if (this.isCurrentMapAis) {
				if (this.$store.getters.getMapAisStyleVisualization == TYPE_VIS) {
					return this.typeColor;
				} else if (this.$store.getters.getMapAisStyleVisualization == STATUS_VIS) {
					return this.statusColor;
				} else {
					return this.operationsColor;
				}
			} else {
				if (this.$store.getters.getMapPeriodsStyleVisualization == TYPE_VIS) {
					return this.typeColor;
				} else if (this.$store.getters.getMapPeriodsStyleVisualization == STATUS_VIS) {
					return this.statusColor;
				} else {
					return this.operationsColor;
				}
			}
		}
	},
	methods: {
		toggleAuthorizedAction() {
			if (this.stop.isRemovedToAuthorized) {
				this.addToMap(this.stop);
			} else {
				this.removeMap(this.stop);
			}
			this.$forceUpdate();
		},
		openPanel(stop) {
			if (this.currentmap == MAP_AIS_ID) {
				const identifier = stop.isAis ? stop.mmsi : stop.id;
				const options = getPanelOptions(identifier, stop);
				this.$store.commit('addDynamicPanelAIS', options);
				this.$store.commit('setActivePanelAIS', options);
			} else {
				const activePanelInstant =
					this.activePanelOptions && this.activePanelOptions.panelId.includes('::')
						? this.activePanelOptions.instantPanel
						: this.activePanel;
				const options = getPanelOptions(stop.id, stop, activePanelInstant);
				this.$store.commit('addDynamicPanelPeriods', options);
				this.$store.commit('setActivePanelPeriods', options);
			}
		},

		open() {
			this.$emit('open');
		},

		addToMap(st) {
			if (this.isPlanningPanelId) {
				var featureRemoved = this.$store.getters.getFeaturesInstantRemovedPL.find((stop) => {
					return stop.id === st.id;
				});
				featureRemoved.isRemovedToAuthorized = false;
				var modified = this.$store.getters.getFeaturesInstantRemovedPL.find((stop) => {
					return stop.modified === true;
				});
				if (this.$store.getters.getStopsFeaturesInstantWithModifiedPL.length > 0 || modified) {
					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess([...this.$store.getters.getStopsFeaturesInstantWithModifiedPL, featureRemoved]);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Borramos del listado de los barcos borrados
					this.$store.commit('removeStopsFeaturesInstantRemovedPL', featureRemoved);
				} else {
					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess([...this.$store.getters.getStopsFeaturesInstantPL, featureRemoved]);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Borramos del listado de los barcos borrados
					this.$store.commit('removeStopsFeaturesInstantRemovedPL', featureRemoved);
				}
			} else {
				var featureRemoved = this.$store.getters.getFeaturesInstantRemovedAU.find((stop) => {
					return stop.id === st.id;
				});
				featureRemoved.isRemovedToAuthorized = false;
				var modified = this.$store.getters.getFeaturesInstantRemovedAU.find((stop) => {
					return stop.modified === true;
				});
				if (this.$store.getters.getStopsFeaturesInstantWithModifiedAU.length > 0 || modified) {
					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess([...this.$store.getters.getStopsFeaturesInstantWithModifiedAU, featureRemoved]);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Borramos del listado de los barcos borrados
					this.$store.commit('removeStopsFeaturesInstantRemovedAU', featureRemoved);
				} else {
					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess([...this.$store.getters.getStopsFeaturesInstantAU, featureRemoved]);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Borramos del listado de los barcos borrados
					this.$store.commit('removeStopsFeaturesInstantRemovedAU', featureRemoved);
				}
			}
		},

		removeMap(st) {
			if (this.isPlanningPanelId) {
				var stopRemovedModified = this.$store.getters.getStopsFeaturesInstantModifiedPL.find((stop) => {
					return stop.id === st.id;
				});

				if (stopRemovedModified) {
					stopRemovedModified.isRemovedToAuthorized = true;
					// Obtemos los barcos sin tener en cuenta el que quitamos
					const stopsFeaturesInstantModified = this.$store.getters.getStopsFeaturesInstantWithModifiedPL.filter((stop) => {
						return stop.id !== st.id;
					});

					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess(stopsFeaturesInstantModified);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Añadimos en un listado los barcos borrados
					this.$store.commit('addStopsFeaturesInstantRemovedPL', stopRemovedModified);
				} else {
					// Obtemos el barco a quitar
					var stopRemoved = this.$store.getters.getStopsFeaturesInstantPL.find((stop) => {
						return stop.id === st.id;
					});

					stopRemoved.isRemovedToAuthorized = true;

					// Obtemos los barcos sin tener en cuenta el que quitamos
					const stopsFeaturesInstant = this.$store.getters.getStopsFeaturesInstantPL.filter((stop) => {
						return stop.id !== st.id;
					});

					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess(stopsFeaturesInstant);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Añadimos en un listado los barcos borrados
					this.$store.commit('addStopsFeaturesInstantRemovedPL', stopRemoved);
				}

				// Eliminamos el panel si esta abierto
				const id = st.id;
				const pk = { id, STOP_INSTANT_LAYER_ID, PLANNING_PANEL_ID };
				const panelId = Object.values(pk).join('::');
				this.$store.commit('removeDynamicPanelPeriodsByPanelId', panelId);
			} else {
				var stopRemovedModified = this.$store.getters.getStopsFeaturesInstantModifiedAU.find((stop) => {
					return stop.id === st.id;
				});

				if (stopRemovedModified) {
					stopRemovedModified.isRemovedToAuthorized = true;
					// Obtemos los barcos sin tener en cuenta el que quitamos
					const stopsFeaturesInstantModified = this.$store.getters.getStopsFeaturesInstantWithModifiedAU.filter((stop) => {
						return stop.id !== st.id;
					});

					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess(stopsFeaturesInstantModified);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Añadimos en un listado los barcos borrados
					this.$store.commit('addStopsFeaturesInstantRemovedAU', stopRemovedModified);
				} else {
					// Obtemos el barco a quitar
					var stopRemoved = this.$store.getters.getStopsFeaturesInstantAU.find((stop) => {
						return stop.id === st.id;
					});

					stopRemoved.isRemovedToAuthorized = true;

					// Obtemos los barcos sin tener en cuenta el que quitamos
					const stopsFeaturesInstant = this.$store.getters.getStopsFeaturesInstantAU.filter((stop) => {
						return stop.id !== st.id;
					});

					// Volvemos a procesar los barcos
					const cleanFeatures = this.cleanStopsToProcess(stopsFeaturesInstant);
					this.$puiEvents.$emit('stopfeaturecreator:updated', cleanFeatures);

					// Añadimos en un listado los barcos borrados
					this.$store.commit('addStopsFeaturesInstantRemovedAU', stopRemoved);
				}

				// Eliminamos el panel si esta abierto
				const id = st.id;
				const pk = { id, STOP_INSTANT_LAYER_ID, REQUESTEDS_PANEL_ID };
				const panelId = Object.values(pk).join('::');
				this.$store.commit('removeDynamicPanelPeriodsByPanelId', panelId);
			}
		}
	}
};
</script>
<style lang="postcss">
.v-application .text-h6 {
	font-family: Overpass, Montserrat, sans-serif !important;
}

.centered {
	padding-top: 8px;
	width: 30px;
	margin: auto;
	margin-right: 2px;
}
</style>
