<template>
	<div class="stops-panel__items">
		<v-expansion-panels multiple flat tile outlined class="my-4" v-model="panels">
			<v-expansion-panel
				v-for="(stop, index) in stops"
				:key="index"
				outlined
				class="mb-2"
				:style="{
					border: `${stop.selected ? '2px solid #2d78ef' : '1px solid #dadde0'}`,
					borderRadius: '5px',
					backgroundColor: `${stop.modified ? '#ddecf6' : '#FFFFFF'}`
				}"
			>
				<v-expansion-panel-header
					class="card-header"
					hide-actions
					:style="{ height: '70px', backgroundcolor: stop.select ? '#ddecf6' : '#FFFFFF' }"
				>
					<button
						v-if="isPlanAuthPanel"
						:class="stop.selected ? ' select-btn-selected' : 'select-btn-unselected'"
						@click.stop="toggleSelect(stop)"
					></button>
					<cards-panel-item-header :stop="stop" @open="open(show + `${index}`)" :currentmap="currentmap"></cards-panel-item-header>
				</v-expansion-panel-header>
				<v-divider></v-divider>

				<v-expansion-panel-content>
					<cards-panel-item-content v-show="show + `${index}`" :stop="stop"></cards-panel-item-content>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<div class="switchoff d-flex flex-column align-center justify-center mt-3" v-if="!active">
			<v-icon size="2rem"> fal fa-toggle-off </v-icon>

			<div class="switchoff-text">{{ switchoffText }}</div>
		</div>
	</div>
</template>

<script>
//style="border: 1px solid #dadde0; border-radius: 5px"
// :style="{ border: `${stop.modified ? '2px solid #FFF554' : '1px solid #dadde0'}`, borderRadius: '5px' }"
import CardsPanelItemHeader from './CardsPanelItemHeader.vue';
import CardsPanelItemContent from './CardsPanelItemContent.vue';

/** Constants */
import { MAP_AIS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';
import { REQUESTEDS_PANEL_ID, PLANNING_PANEL_ID } from '@/lib/variables/panels';
export default {
	components: { CardsPanelItemHeader, CardsPanelItemContent },
	props: {
		stops: {
			type: Array,
			required: true
		},
		all: {
			type: Boolean,
			required: true
		},
		/**
		 * Layer active
		 */
		active: {
			type: Boolean,
			required: false,
			default: true
		},
		switchoffText: {
			type: String,
			required: false
		},
		currentmap: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			pageItemSize: 10,
			currentPage: null,
			paginatedStops: [],
			firstLoad: true,
			show: false,
			panels: []
		};
	},
	computed: {
		isCurrentMapAis() {
			return this.currentmap == MAP_AIS_ID;
		},
		isPlanningPanelId() {
			if (this.isCurrentMapAis) {
				return false;
			}
			if (this.$store.getters.panelParentActive == PLANNING_PANEL_ID) {
				return true;
			}
			return false;
		},
		isPlanAuthPanel() {
			if (this.isCurrentMapAis) {
				return false;
			}
			if (this.$store.getters.panelParentActive == PLANNING_PANEL_ID || this.$store.getters.panelParentActive == REQUESTEDS_PANEL_ID) {
				return true;
			}
			return false;
		}
	},
	watch: {
		currentPage(newVal) {
			if (newVal === null) {
				return;
			}
			if (this.stops.length === 0) {
				return;
			}
			const from = this.currentPage * this.pageItemSize;
			const until = (this.currentPage + 1) * this.pageItemSize;
			for (let i = from; i < until; i++) {
				if (i === this.stops.length) {
					break;
				}
				this.paginatedStops.push(this.stops[i]);
			}
		},
		stops(value) {
			if (value && value.length) {
				this.resetPage(true);
			}
		},
		all() {
			this.openAll();
		}
	},
	mounted() {
		this.resetPage(true);
	},
	methods: {
		toggleSelect(stop) {
			if (stop?.modified && stop?.entrydate && stop?.destinationdate && stop?.berthingtype && stop?.berthid) {
				if (this.isPlanningPanelId) {
					if (this.$store.getters.getStopsFeaturesInstantWithModifiedPL.length > 0) {
						const stopsModified = this.$store.getters.getStopsFeaturesInstantModifiedPL.map((st) => {
							if (st.id == stop.id) {
								st.selected = !stop.selected;
							}
							return st;
						});
						const stopsWithModified = this.$store.getters.getStopsFeaturesInstantWithModifiedPL.map((st) => {
							if (st.id == stop.id) {
								st.selected = !stop.selected;
							}
							return st;
						});

						this.$store.commit('setStopsFeaturesInstantModifiedPL', stopsModified);
						this.$store.commit('setStopsFeaturesInstantWithModifiedPL', stopsWithModified);
					} else {
						const stops = this.$store.getters.getStopsFeaturesInstantPL.map((st) => {
							if (st.id == stop.id) {
								st.selected = !stop.selected;
							}
							return st;
						});

						this.$store.commit('setStopsFeaturesInstantModifiedPL', [{ ...stop }]);
						this.$store.commit('setStopsFeaturesInstantWithModifiedPL', stops);
					}
				} else {
					if (this.$store.getters.getStopsFeaturesInstantWithModifiedAU.length > 0) {
						const stopsModified = this.$store.getters.getStopsFeaturesInstantModifiedAU.map((st) => {
							if (st.id == stop.id) {
								st.selected = !stop.selected;
							}
							return st;
						});
						const stopsWithModified = this.$store.getters.getStopsFeaturesInstantWithModifiedAU.map((st) => {
							if (st.id == stop.id) {
								st.selected = !stop.selected;
							}
							return st;
						});

						this.$store.commit('setStopsFeaturesInstantModifiedAU', stopsModified);
						this.$store.commit('setStopsFeaturesInstantWithModifiedAU', stopsWithModified);
					} else {
						const stops = this.$store.getters.getStopsFeaturesInstantAU.map((st) => {
							if (st.id == stop.id) {
								st.selected = !stop.selected;
							}
							return st;
						});

						this.$store.commit('setStopsFeaturesInstantModifiedAU', [{ ...stop }]);
						this.$store.commit('setStopsFeaturesInstantWithModifiedAU', stops);
					}
				}
			} else {
				if (!stop?.modified || stop?.modified == null) {
					this.$puiNotify.error(this.$puiI18n.t('shipsituation.planned.plan.notEdited'));
				} else {
					this.$puiNotify.error(this.$puiI18n.t('shipsituation.planned.plan.requiredFields'));
				}
			}
		},
		open(index) {
			index = !index;
		},

		resetPage(stopsProcessedLength) {
			this.firstLoad = true;
			this.paginatedStops = [];
			if (this.stops.length) {
				this.currentPage = stopsProcessedLength ? 0 : null;
				this.firstLoad = false;
			}
		},

		openAll() {
			if (this.all) {
				for (let index = 0; index < this.stops.length; index++) {
					this.panels.push(index);
				}
			} else {
				this.panels = [];
			}
		}
	}
};
</script>

<style lang="postcss">
.stops-panel__items {
	height: calc(100vh - var(--stops-panel-status-filters-height));
}

.select-btn-selected {
	position: absolute;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	top: 0;
	left: 0;
	width: 5px;
	height: 100%;
	background: var(--primary);
	border: none;
	cursor: pointer;
}

.select-btn-unselected {
	position: absolute;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	top: 0;
	left: 0;
	width: 5px;
	height: 100%;
	background: transparent;
	border: none;
	cursor: pointer;
}

.select-btn:hover {
	transition: width 0.6s;
}

.v-expansion-panel-header:hover .select-btn-selected {
	width: 15px; /* Cambia el ancho cuando se pasa el ratón por encima del encabezado */
}

.v-expansion-panel-header:hover .select-btn-unselected {
	background: #cfddfa;
	width: 15px; /* Cambia el ancho cuando se pasa el ratón por encima del encabezado */
}
</style>
